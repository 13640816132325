import React, { useEffect, useState } from "react"
import Layout from "@/components/layout"
import TermsCondition from "@/components/app-terms"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"
import { Container } from "react-bootstrap"
import LogoApp from "../assets/images/logosvg.svg"

const TermsPage = () => {

  const [date, setDate] = useState()
  useEffect(() => {
    const date = new Date().getFullYear()
    setDate(date)
  }, [])

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="TCs">
          <div className="container">
            <img className="app-page-logo" src={LogoApp}></img>
          </div>
          <section className="commonSection blogDetails">
            <Container>
              <div className="terms">
                <TermsCondition />
              </div>
            </Container>
          </section>
          <footer className="footer_1" style={{ padding: "0px" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-sm-12 text-center">
                  <div className="copyright">
                    {/* © copyright {new Date().getFullYear()} by scoreblox.com{" "} */}
                    © copyright {date} by scoreblox.com{" "}
                    <br /> a Kawntr Limited venture - All rights reserved
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  )
}

export default TermsPage
